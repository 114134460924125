import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchReviewStats:
      return { ...state, reviewStats: payload.reviewStats };
    case ActionTypes.FetchReviewStatsByRating:
      return { ...state, reviewStatsByRating: payload.reviewStatsByRating };
    default:
      return state;
  }
};
