import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
//import Home from './containers/Home';

const Review_lazyPromise = import('./containers/Reviews');
let Review_lazy = lazy(() => Review_lazyPromise);

const ConfirmReservation_lazyPromise = import('./containers/ConfirmReservation');
let ConfirmReservation_lazy = lazy(() => ConfirmReservation_lazyPromise);

const OrderTracker_lazyPromise = import('./containers/OrderTracker');
let OrderTracker_lazy = lazy(() => OrderTracker_lazyPromise);

const Home_lazyPromise = import('./containers/Home');
let Home_lazy = lazy(() => Home_lazyPromise);

// let Reviews = Loadable({
//   loader: () =>
//     import('./containers/Reviews' /* webpackChunkName: "post_order_review" */),
//   loading: () => <div />,
// });

// let ConfirmReservation = Loadable({
//   loader: () =>
//     import('./containers/ConfirmReservation' /* webpackChunkName: "confirm_reservation" */),
//   loading: () => <div />,
// });

// let OrderTracker = Loadable({
//   loader: () =>
//     import('./containers/OrderTracker' /* webpackChunkName: "order_tracking" */),
//   loading: () => <div />,
// });

// OrderTracker.preload();

export default () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Switch>
          <Route
            path='/order-review'
            render={props => <Review_lazy {...props} />}
          />
          <Route
            path='/order-tracking'
            render={props => <OrderTracker_lazy {...props} />}
          />
          <Route
            path='/confirm-booking'
            render={props => <ConfirmReservation_lazy {...props} />}
          />
          <Route path='/' render={props => <Home_lazy {...props} />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
