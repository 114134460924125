import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchAllVouchers:
      return { ...state, allVouchers: payload.allVouchers };
    case ActionTypes.FetchedCreditCards: {
      return { ...state, paymentCards: payload.cards };
    }
    default:
      return state;
  }
};
