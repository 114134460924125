import ActionTypes from './constants';

export default (state = [], payload) => {
  switch (payload.type) {
    case ActionTypes.FetchStoreTimings:
      return { ...state, currentShift: payload.result };
    case ActionTypes.FetchStoreTimingsArray:
      return { ...state, ...payload.result };
    case ActionTypes.FetchAllSuburbs:
      return { ...state, allSuburbs: payload.allSuburbs };
    case ActionTypes.FetchAllShifts:
      return { ...state, allShifts: payload.allShifts };
    case ActionTypes.FetchedIngredientCategories:
      return { ...state, ingredientCategories: payload.ingredientCategories };
    case ActionTypes.FetchCloseDates:
      return { ...state, closeDates: payload.closeDates };
    default:
      return state;
  }
};
